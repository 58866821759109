import React, { useState } from 'react';
import Slider from 'react-slick';
import downloadMilaApp from 'assets/images/download-mila-app.png';
import downloadMilaAppWebp from 'assets/images/download-mila-app.png?as=webp';
import turnOnMilaApp from 'assets/images/turn-on-mila-app.png';
import turnOnMilaAppWebp from 'assets/images/turn-on-mila-app.png?as=webp';
import pairYourMila from 'assets/images/pair-your-mila.png';
import pairYourMilaWebp from 'assets/images/pair-your-mila.png?as=webp';
import appleStore from 'assets/images/apple-store.svg';
import googlePlayStore from 'assets/images/google-play-store.svg';
import LazyLoad from 'react-lazyload';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import { SUPPORTED_REGIONS } from '../../../shared/enums';
import { selectLocalizationData } from '../../features/localization/localizationSlice';

const LazyImg = loadable(() => import('../common/LazyImg'));
const FadeIn = loadable(() => import('../common/FadeIn'));

/**
 * @param imagesSettings
 * @param setImageSlider
 * @returns {JSX.Element}
 * @constructor
 */
const InstallBlockImageCarousel = ({ imagesSettings, setImageSlider }) => (
  <Slider
    className='install-block__image-carousel'
    {...imagesSettings}
    ref={slider => setImageSlider(slider)}
  >
    <div className='install-block__image-slide'>
      <LazyLoad
        height={846}
        placeholder={(
          <div
            className='image-placeholder'
            style={{ aspectRatio: `${571 / 846} ` }}
          />
        )}
      >
        <FadeIn>
          <picture>
            <source srcSet={downloadMilaAppWebp} type='image/webp' />
            <source srcSet={downloadMilaApp} type='image/png' />
            <img src={downloadMilaApp} alt='download-mila-app' width={571} height={846} />
          </picture>
        </FadeIn>
      </LazyLoad>
    </div>
    <div className='install-block__image-slide'>
      <LazyLoad
        height={846}
        placeholder={(
          <div
            className='image-placeholder'
            style={{ aspectRatio: `${571 / 846} ` }}
          />
        )}
      >
        <FadeIn>
          <picture>
            <source srcSet={turnOnMilaAppWebp} type='image/webp' />
            <source srcSet={turnOnMilaApp} type='image/png' />
            <img src={turnOnMilaApp} alt='turn-on-mila-app' width={571} height={846} />
          </picture>
        </FadeIn>
      </LazyLoad>
    </div>
    <div className='install-block__image-slide'>
      <LazyLoad
        height={846}
        placeholder={(
          <div
            className='image-placeholder'
            style={{ aspectRatio: `${571 / 846} ` }}
          />
        )}
      >
        <FadeIn>
          <picture>
            <source srcSet={pairYourMilaWebp} type='image/webp' />
            <source srcSet={pairYourMila} type='image/png' />
            <img src={pairYourMila} alt='pair-your-mila' width={571} height={846} />
          </picture>
        </FadeIn>
      </LazyLoad>
    </div>
  </Slider>
);

/**
 * @param cardsSettings
 * @param setCardsSlider
 * @returns {JSX.Element}
 * @constructor
 */
const InstallBlockCardCarousel = ({ cardsSettings, setCardsSlider }) => {
  const localization = useSelector(selectLocalizationData);
  const isRegionMatch = localization.region === SUPPORTED_REGIONS.CA;
  return (
    <Slider
      className='install-block__card-carousel'
      {...cardsSettings}
      ref={slider => setCardsSlider(slider)}
    >
      <div className='install-block__card-slide'>
        <span className='install-block__card-number'>01</span>
        <h3 className='install-block__card-title'>Grab the app.</h3>
        <div className='install-block__card-description'>
          <p>Download the Mila app on your iOS/Android device.</p>
        </div>
        <div className='app-link app-link--small'>
          <a
            href={`${isRegionMatch ? 'https://apps.apple.com/ca/app/mila-air/id1520776049' : 'https://apps.apple.com/us/app/mila-air/id1520776049'}`}
            target='_blank'
            className='btn btn--black btn--apple-icon'
            rel='noreferrer'
          >
            <LazyImg
              src={appleStore}
              alt='App Store'
            />
            App Store
          </a>
          <a
            href='https://play.google.com/store/apps/details?id=com.mila.androidv2'
            target='_blank'
            className='btn btn--white'
            rel='noreferrer'
          >
            <LazyImg
              src={googlePlayStore}
              alt='Google Play Store'
            />
            Google Play
          </a>
        </div>
      </div>
      <div className='install-block__card-slide'>
        <span className='install-block__card-number'>02</span>
        <h3 className='install-block__card-title'>Turn on your Mila air purifier.</h3>
        <div className='install-block__card-description'>
          <p>Plug in and power on each unit.</p>
        </div>
      </div>
      <div className='install-block__card-slide'>
        <span className='install-block__card-number'>03</span>
        <h3 className='install-block__card-title'>Follow your in-app directions.</h3>
        <div className='install-block__card-description'>
          <p>
            Set up your Mila in three simple steps. You’ll want to have your local WiFi
            network name and password
            handy (Mila works on a 2.4 GHz frequency).
          </p>
        </div>
      </div>
    </Slider>
  );
};

const InstallBlock = () => {
  const [ cardsSlider, setCardsSlider ] = useState(null);
  const [ imageSlider, setImageSlider ] = useState(null);

  const imagesSettings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    fade: true,
    asNavFor: cardsSlider,
    autoplay: false,
    speed: 1000,
    pauseOnHover: false,
    pauseOnFocus: false,
    swipe: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          swipe: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          swipe: true,
        },
      },
      {
        breakpoint: 550,
        settings: {
          swipe: true,
        },
      },
    ],
  };

  const cardsSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: false,
    dots: false,
    vertical: true,
    verticalSwiping: true,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 2000,
    pauseOnHover: false,
    pauseOnFocus: false,
    focusOnSelect: true,
    asNavFor: imageSlider,
    adaptiveHeight: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          vertical: false,
          verticalSwiping: false,
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 767,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '100px',
          adaptiveHeight: false,
        },
      },
      {
        breakpoint: 550,
        settings: {
          vertical: false,
          verticalSwiping: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
          centerPadding: '25px',
          adaptiveHeight: false,
        },
      },
    ],
  };

  return (
    <section id='app-installation' className='install-block'>
      <div className='container'>
        <div className='install-block__intro'>
          <h3 className='install-block__intro-subtitle'>Mila on the go</h3>
          <h2 className='install-block__intro-title'>Setting up your Mila app</h2>
          <div className='install-block__intro-description'>
            <p>It’s as easy as inhale, exhale.</p>
          </div>
        </div>
        <div className='install-block__carousel-wrap'>
          <InstallBlockImageCarousel
            imagesSettings={imagesSettings}
            setImageSlider={setImageSlider}
          />
          <div className='install-block__card-wrapper'>
            <InstallBlockCardCarousel
              cardsSettings={cardsSettings}
              setCardsSlider={setCardsSlider}
            />
          </div>
        </div>
        <div className='install-block__bottom-content'>
          <p>
            Get the latest updates on Mila’s services at
            {' '}
            <a
              href='https://status.milacares.com'
              target='_blank'
              rel='noreferrer'
            >
              https://status.milacares.com
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};
export default InstallBlock;
